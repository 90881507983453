import React, { useState, useEffect } from 'react';
import CustomerList from './CustomerList';
import Header from '../Header';

const Customers = () => {
    const [customers, setCustomers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [customerSource, setCustomerSource] = useState('Sellix'); // New state for customer source

    const fetchSellixCustomers = async () => {
        const userData = JSON.parse(localStorage.getItem('user'));
        const options = { method: 'GET', headers: { Authorization: userData.sellixApiKey } };
        const url = 'https://dev.sellix.io/v1/customers';

        try {
            const response = await fetch(url, options);
            const data = await response.json();
            console.log(data?.data?.customers);
            setCustomers(data?.data?.customers || []);
        } catch (err) {
            console.error('Error fetching Sellix customers:', err);
        }
    };

    const fetchBillgangCustomers = async () => {
        const userData = JSON.parse(localStorage.getItem('user'));
        const options = { 
            method: 'POST',  // Example: Billgang may use POST instead of GET
            headers: { 
                Authorization: `Bearer ${userData.billgangApiKey}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                // Example payload for Billgang API
                filter: 'active', // Adjust according to Billgang API requirements
            }),
        };
        const url = 'https://api.billgang.io/v1/customers'; // Replace with actual Billgang API URL

        try {
            const response = await fetch(url, options);
            const data = await response.json();
            console.log(data?.customers);
            setCustomers(data?.customers || []);
        } catch (err) {
            console.error('Error fetching Billgang customers:', err);
        }
    };

    useEffect(() => {
        if (customerSource === 'Sellix') {
            fetchSellixCustomers();
        } else if (customerSource === 'Billgang') {
            fetchBillgangCustomers();
        }
    }, [customerSource]);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSourceChange = (e) => {
        setCustomerSource(e.target.value); // Update the customer source
    };

    const filteredCustomers = customers.filter((customer) =>
        customer.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="min-h-screen bg-gray-100">
            <Header />
            <div className="container mx-auto p-4">
                <h1 className="text-3xl font-bold mb-6">Customer Management</h1>
                <div className="mb-8">
                    <div className="flex items-center mb-4">
                        <input
                            type="text"
                            placeholder="Search by customer name"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            className="px-4 py-2 border border-gray-300 rounded-md shadow-sm w-64 mr-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <select
                            value={customerSource}
                            onChange={handleSourceChange}
                            className="px-4 py-2 border border-gray-300 rounded-md shadow-sm ml-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                            <option value="Sellix">Sellix</option>
                            <option value="Billgang">Billgang</option>
                        </select>
                    </div>
                </div>
                <CustomerList customers={filteredCustomers} />
            </div>
        </div>
    );
};

export default Customers;
