import React, { useState, useEffect } from 'react';
import ProductList from './ProductList';
import ProductForm from './ProductForm';
import Header from '../Header';

const Products = () => {
    const [products, setProducts] = useState([]);
    const [currentProduct, setCurrentProduct] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [productSource, setProductSource] = useState('Sellix'); // New state for product source

    const fetchProducts = async () => {
        const userData = JSON.parse(localStorage.getItem('user'));
        const options = { method: 'GET', headers: { Authorization: userData.sellixApiKey } };

        let url = '';
        if (productSource === 'Sellix') {
            url = 'https://dev.sellix.io/v1/products';
        } else if (productSource === 'Billgang') {
            url = 'https://api.billgang.io/v1/products'; // Replace with actual Billgang API URL
        }

        try {
            const response = await fetch(url, options);
            const data = await response.json();
            console.log(data?.data?.products);
            setProducts(data?.data?.products || []);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        fetchProducts();
    }, [productSource]);

    const handleSaveProduct = (product) => {
        fetchProducts();
        setCurrentProduct(null);
    };

    const handleEditProduct = (product) => {
        setCurrentProduct(product);
    };

    const handleDeleteProduct = async (id) => {
        const userData = JSON.parse(localStorage.getItem('user'));
        const isSellix = productSource === 'Sellix';
        const url = isSellix
            ? `https://dev.sellix.io/v1/products/${id}`
            : `https://api.billgang.io/v1/products/${id}`; // Update with actual Billgang URL

        const apiKey = isSellix ? userData.sellixApiKey : userData.billgangApiKey; // Ensure billgangApiKey exists in userData

        const options = {
            method: 'DELETE',
            headers: {
                Authorization: apiKey,
            },
        };

        try {
            const response = await fetch(url, options);
            const data = await response.json();

            if (data.status === 200) {
                setProducts(products.filter((product) => product.id !== id));
            } else {
                console.error('Error deleting product:', data.message);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSourceChange = (e) => {
        setProductSource(e.target.value); // Update the product source
    };

    const filteredProducts = products.filter((product) =>
        product.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="min-h-screen bg-gray-100">
            <Header />
            <div className="container mx-auto p-4">
                <h1 className="text-3xl font-bold mb-6">Product Management</h1>
                <div className="mb-8">
                    <div className="flex items-center mb-4">
                        <input
                            type="text"
                            placeholder="Search by product name"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            className="px-4 py-2 border border-gray-300 rounded-md shadow-sm w-64 mr-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <select
                            value={productSource}
                            onChange={handleSourceChange}
                            className="px-4 py-2 border border-gray-300 rounded-md shadow-sm ml-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                            <option value="Sellix">Sellix</option>
                            <option value="Billgang">Billgang</option>
                        </select>
                    </div>
                    <ProductForm currentProduct={currentProduct} onSave={handleSaveProduct} platform={productSource} />
                </div>
                <ProductList products={filteredProducts} onEdit={handleEditProduct} onDelete={handleDeleteProduct} />
            </div>
        </div>
    );
};

export default Products;
