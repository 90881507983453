import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth_service } from '../../utils/auth_service';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await auth_service.login({
                email,
                password,
            });
            localStorage.setItem("user", JSON.stringify(response));
            console.log({ response });
            navigate(`/`);
        } catch (error) {
            const msg = error.response.data.message || error.message;
            console.log({ msg });
        }
    };

    const responseMessage = async (resp) => {
        try {
            const { email } = jwtDecode(resp.credential);
            const response = await auth_service.googlesso({
                email
            });
            localStorage.setItem("user", JSON.stringify(response));
            navigate(`/`);
        } catch (error) {
            console.log(error);
        }
    };
    const errorMessage = (error) => {
        console.log(error);
    };


    return (
        <div className="flex items-center justify-center min-h-screen">
            <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-md">
                <form onSubmit={handleLogin} className="space-y-6">
                    <h1 className="text-2xl font-bold text-center mb-4">Sign In</h1>
                    <p className="text-gray-500 text-center">Enter your email & password to login</p>
                    <div className="flex flex-col mb-4">
                        <label htmlFor="email" className="text-gray-700 font-bold mb-1">
                            Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full px-3 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
                            required
                        />
                    </div>
                    <div className="flex flex-col mb-4 relative">
                        <label htmlFor="password" className="text-gray-700 font-bold mb-1">
                            Password
                        </label>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="w-full px-3 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
                            required
                        />
                        <div className="absolute bottom-0 right-0 py-2 flex items-center cursor-pointer mr-3">
                            <span
                                className={`transition duration-200 ease-in-out ${showPassword ? 'text-blue-500' : 'text-gray-400'
                                    }`}
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                    </svg>
                                ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                                    </svg>
                                )}
                            </span>
                        </div>
                    </div>
                    <div className="text-right mb-4">
                        <Link to="/forgot-password" className="text-blue-500 hover:underline">Forgot Password?</Link>
                    </div>
                    <button
                        type="submit"
                        className="w-full px-3 py-2 rounded-lg bg-blue-500 text-white font-bold hover:bg-blue-700 focus:outline-none focus:ring-1 focus:ring-blue-500"
                    >
                        Sign In
                    </button>
                    <div className='flex justify-center'>
                        <GoogleLogin onSuccess={responseMessage} onError={errorMessage} text='signin_with' width={500} />
                    </div>
                    <p className="text-center mb-0 mt-4">
                        Don't have an account?
                        <Link to={`/register`} className="text-blue-500 hover:underline ml-2">
                            Create Account
                        </Link>
                    </p>

                </form>
            </div>
        </div>
    );
}

export default Login;
