import React from 'react';
import Header from '../Header';

const Home = () => {
  return (
    <div className="min-h-full">
      <Header />
      <main className="container mx-auto p-4">
        <div className="text-center mt-20">
          <h1 className="text-4xl font-bold text-gray-800">Hello and Welcome to our application!</h1>
        </div>
      </main>
    </div>
  );
};

export default Home;
