import React, { useEffect, useState } from 'react';
import Header from '../Header';
import { auth_service } from '../../utils/auth_service';

const ProfileSettings = () => {
    const [user, setUser] = useState(null);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        setUser(user);
        setLoading(false);
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }
        try {
            setLoading(true)
            const updatedUserData = await auth_service.updateUser(user._id, { password: newPassword });
            localStorage.setItem('user', JSON.stringify(updatedUserData));
            setError('');
            setNewPassword('');
            setConfirmPassword('');
            setLoading(false)
        } catch (error) {
            setError('Failed to update password');
        }
    };


    return (
        <div className='min-h-screen'>
            <Header />
            <div className="flex items-center justify-center mt-20">
                <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md h-full">
                    <h2 className="text-2xl font-bold mb-6 text-center">Update Profile</h2>
                    {error && <p className="text-red-500 text-center mb-4">{error}</p>}
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div>
                            <label htmlFor="email" className="block text-gray-700">Email</label>
                            <input
                                type="email"
                                id="email"
                                value={user?.email}
                                readOnly
                                className="w-full px-4 py-2 border rounded-lg bg-gray-200 cursor-not-allowed"
                            />
                        </div>
                        <div>
                            <label htmlFor="newPassword" className="block text-gray-700">New Password</label>
                            <input
                                type="password"
                                id="newPassword"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                required
                                autoComplete="off"
                            />
                        </div>
                        <div>
                            <label htmlFor="confirmPassword" className="block text-gray-700">Confirm Password</label>
                            <input
                                type="password"
                                id="confirmPassword"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                required
                                autoComplete="off"
                            />
                        </div>
                        <div className="text-center">
                            <button
                                type="submit"
                                className={`w-full text-white px-4 py-2 rounded-lg ${loading ? "cursor-not-allowed bg-blue-400" : "bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"}`}
                                disabled={loading}
                            >
                                Save Changes
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ProfileSettings;
