import React, { useEffect, useState } from 'react';
import Header from '../Header';
import { auth_service } from '../../utils/auth_service';

const WalletConnector = () => {
  const [user, setUser] = useState(null)
  const [wallets, setWallets] = useState({
    sellix: '',
    billgang: '',
  });

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('user'));
    setUser(userData);
    setWallets({
      sellix: userData?.sellixApiKey || '',
      billgang: userData?.billgangApiKey || ''
    })
  }, [])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setWallets({
      ...wallets,
      [name]: value,
    });
  };

  const connectWallet = async (wallet) => {
    const apiKey = wallets[wallet];
    if (!apiKey) {
      alert(`Please enter the API key for ${wallet}`);
      return;
    }

    try {
      let response;
      if (wallet === 'sellix') {
        response = await fetch('https://dev.sellix.io/v1/self', {
          method: 'GET',
          headers: {
            Authorization: apiKey
          }
        });
      } else if (wallet === 'billgang') {
        response = await fetch('https://pg-api.billgang.com/v1/dash/user', {
          method: 'GET',
          headers: {
            Authorization: apiKey,
          },
        });
      }

      if (response.ok) {
        const data = await response.json();
        console.log(`Connected to ${wallet}`, data);

        const updatedUserData = await auth_service.updateUser(user._id, {
          [wallet === 'sellix' ? 'sellixApiKey' : 'billgangApiKey']: apiKey
        });
        localStorage.setItem('user', JSON.stringify(updatedUserData));
      } else {
        console.error(`Failed to connect to ${wallet}`);
      }
    } catch (error) {
      console.error(`Error connecting to ${wallet}:`, error);
    }
  };

  return (
    <div className="min-h-full">
      <Header />
      <div className="container mx-auto p-4">
        <h2 className="text-2xl font-bold mb-4">Connect Your Wallets</h2>
        <div className="mb-4">
          <label className="block text-gray-700">
            Sellix API Key:
            <input
              type="text"
              name="sellix"
              value={wallets.sellix}
              onChange={handleInputChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
          </label>
          <button
            onClick={() => connectWallet('sellix')}
            className="mt-2 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
          >
            Connect Sellix
          </button>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">
            Billgang API Key:
            <input
              type="text"
              name="billgang"
              value={wallets.billgang}
              onChange={handleInputChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
          </label>
          <button
            onClick={() => connectWallet('billgang')}
            className="mt-2 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
          >
            Connect Billgang
          </button>
        </div>
      </div>
    </div>
  );
};

export default WalletConnector;
