import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { auth_service } from '../../utils/auth_service';
import { jwtDecode } from "jwt-decode";

function ResetPassword() {
    const { resetToken } = useParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);

    const handleResetPassword = async (e) => {
        e.preventDefault();
        try {
            const { email } = jwtDecode(resetToken);
            if (password !== confirmPassword) {
                setError('Passwords do not match');
                return;
            }

            const userData = await auth_service.getUser({ email })
            console.log(userData);

            if (userData?.existingUser?._id) {
                await auth_service.updateUser(userData.existingUser._id, { password })
                setSuccess(true);
            } else {
                setError(error.message || 'An error occurred while resetting your password.');
            }
        } catch (error) {
            setError(error.message || 'An error occurred while resetting your password.');
        }
    };

    if (success) {
        return <div>Password reset successfully. You can now login with your new password.</div>;
    }

    return (
        <div className="flex items-center justify-center min-h-screen">
            <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-md">
                <form onSubmit={handleResetPassword} className="space-y-6">
                    <h1 className="text-2xl font-bold text-center mb-4">Reset Password</h1>
                    <div className="flex flex-col mb-4">
                        <label htmlFor="password" className="text-gray-700 font-bold mb-1">
                            New Password
                        </label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="w-full px-3 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
                            required
                        />
                    </div>
                    <div className="flex flex-col mb-4">
                        <label htmlFor="confirmPassword" className="text-gray-700 font-bold mb-1">
                            Confirm New Password
                        </label>
                        <input
                            type="password"
                            id="confirmPassword"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className="w-full px-3 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full px-3 py-2 rounded-lg bg-blue-500 text-white font-bold hover:bg-blue-700 focus:outline-none focus:ring-1 focus:ring-blue-500"
                    >
                        Reset Password
                    </button>
                    {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
                </form>
            </div>
        </div>
    );
}

export default ResetPassword;
