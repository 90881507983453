import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth_service } from '../../utils/auth_service';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';

const Register = () => {
  const [fetchedRoles, setFetchedRoles] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await auth_service.fetchRoles();
        const roles = response.roles.map(str => str.charAt(0).toUpperCase() + str.slice(1));
        setFetchedRoles(roles);
      } catch (error) {
        console.log({ error: error.response.data.message });
      }
    };
    fetchData();
  }, []);

  const [togglePassword, setTogglePassword] = useState(false);
  const navigate = useNavigate();

  const [rolesArray, setRolesArray] = useState([]);

  const handleRole = ({ target: { value, checked } }) => {
    if (checked) {
      setRolesArray(prev => ([...prev, value]))
    }
    else {
      setRolesArray(prev => prev.filter((role) => (role !== value)));
    }
  }

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const { target: { elements: { email, password } } } = e;

      const response = await auth_service.register({
        email: email.value,
        password: password.value,
        roles: rolesArray
      })
      if (response) {
        const loginResponse = await auth_service.login({
          email: email.value,
          password: password.value
        })
        if (loginResponse) {
          localStorage.setItem("user", JSON.stringify(loginResponse))
          navigate(`/`)
        }
      }
    } catch (error) {
      const msg = error.response?.data?.message || error.message;
      console.log({ error: msg });
    }
  }

  const responseMessage = async (resp) => {
    try {
      const { email } = jwtDecode(resp.credential);
      const response = await auth_service.googlesso({
        email
      });
      localStorage.setItem("user", JSON.stringify(response));
      navigate(`/`);
    } catch (error) {
      console.log(error);
    }
  };
  const errorMessage = (error) => {
    console.log(error);
  };

  return (
    <Fragment>
      <div className='flex items-center justify-center min-h-screen'>
        <div className='w-full max-w-md p-6 bg-white rounded shadow-md'>
          <form className='space-y-6' onSubmit={handleRegister}>
            <h4 className='text-xl font-semibold text-center'>Create your account</h4>
            <p className='text-center text-gray-600'>Enter your details to create an account</p>
            <div>
              <label className='block mb-2 text-sm font-medium text-gray-700'>Email Address</label>
              <input className='block w-full p-2 border border-gray-300 rounded' type='email' name='email' required placeholder='Test@gmail.com' />
            </div>
            <div className='relative'>
              <label className='block mb-2 text-sm font-medium text-gray-700'>Password</label>
              <input className='block w-full p-2 border border-gray-300 rounded' type={togglePassword ? 'text' : 'password'} name='password' required placeholder='*********' />
              <div className="absolute bottom-0 right-0 py-2 flex items-center cursor-pointer mr-3">
                <span
                  className={`transition duration-200 ease-in-out ${togglePassword ? 'text-blue-500' : 'text-gray-400'
                    }`}
                  onClick={() => setTogglePassword(!togglePassword)}
                >
                  {togglePassword ? (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
                    </svg>
                  )}
                </span>
              </div>
            </div>
            <div>
              <span className='block mb-2 text-sm font-medium text-gray-700'>Select your role (only if applicable)</span>
              <div className='space-y-2'>
                {fetchedRoles.map((role, index) => (
                  <div key={role} className='flex items-center'>
                    <input id={role} type='checkbox' name={role} value={role} onChange={handleRole} className='h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500' />
                    <label htmlFor={role} className='ml-2 text-sm text-gray-700'>{role}</label>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <button type='submit' className='w-full px-4 py-2 font-medium text-white bg-blue-600 rounded hover:bg-blue-700 focus:outline-none focus:bg-blue-700'>Create Account</button>
            </div>
            <div className='flex justify-center'>
              <GoogleLogin onSuccess={responseMessage} onError={errorMessage} text='signup_with' width={500} />
            </div>
            <p className='text-center text-sm text-gray-600'>
              Already have an account?
              <Link className='ml-2 text-blue-600 hover:underline' to={`/login`}>
                Sign in
              </Link>
            </p>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default Register;
