import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from "./components/Home";
import PrivateRoute from "./PrivateRoute";
import Login from './components/Login';
import Register from "./components/Register";
import ProfileSettings from "./components/ProfileSettings";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import WalletConnector from "./components/WalletConnector";
import Customers from "./components/Customers";
import Products from "./components/Products";

const router = createBrowserRouter([
  {
    path: "/",
    element: <PrivateRoute><Home /></PrivateRoute>,
  },
  {
    path: "/profile",
    element: <PrivateRoute><ProfileSettings /></PrivateRoute>,
  },
  {
    path: "/wallet-connector",
    element: <PrivateRoute><WalletConnector /></PrivateRoute>,
  },
  {
    path: "/customers",
    element: <PrivateRoute><Customers /></PrivateRoute>,
  },
  {
    path: "/products",
    element: <PrivateRoute><Products /></PrivateRoute>,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/reset-password/:resetToken",
    element: <ResetPassword />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
