import React, { useState, useEffect } from 'react';

const ProductForm = ({ currentProduct, onSave, platform }) => {
  const [product, setProduct] = useState({
    title: '',
    price: '',
    description: '',
    currency: '',
    stock: '',
    service_text: '',
    gateways: [],
    type: 'SERIALS',
    serials: [],
    serials_remove_duplicates: false,
    dynamic_webhook: '',
    stock_delimiter: '',
    min_quantity: 0,
    max_quantity: 0,
    delivery_text: '',
    custom_fields: [{}],
    crypto_confirmations_needed: 0,
    max_risk_level: 0,
    unlisted: false,
    private: false,
    block_vpn_proxies: false,
    sort_priority: 0,
    webhooks: [],
    on_hold: false,
    terms_of_service: '',
    warranty: 0,
    warranty_text: '',
    remove_image: false,
    remove_file: false,
    volume_discounts: [{}],
    recurring_interval: '',
    recurring_interval_count: 0,
    trial_period: 0,
  });

  useEffect(() => {
    if (currentProduct) {
      setProduct({
        title: currentProduct.title,
        price: currentProduct.price,
        description: currentProduct.description,
        currency: currentProduct.currency,
        stock: currentProduct.stock,
        service_text: currentProduct.service_text,
        gateways: currentProduct.gateways || [],
        type: currentProduct.type || 'SERIALS',
        serials: currentProduct.serials || [],
        serials_remove_duplicates: currentProduct.serials_remove_duplicates || false,
        dynamic_webhook: currentProduct.dynamic_webhook || '',
        stock_delimiter: currentProduct.stock_delimiter || '',
        min_quantity: currentProduct.min_quantity || 0,
        max_quantity: currentProduct.max_quantity || 0,
        delivery_text: currentProduct.delivery_text || '',
        custom_fields: currentProduct.custom_fields || [{}],
        crypto_confirmations_needed: currentProduct.crypto_confirmations_needed || 0,
        max_risk_level: currentProduct.max_risk_level || 0,
        unlisted: currentProduct.unlisted || false,
        private: currentProduct.private || false,
        block_vpn_proxies: currentProduct.block_vpn_proxies || false,
        sort_priority: currentProduct.sort_priority || 0,
        webhooks: currentProduct.webhooks || [],
        on_hold: currentProduct.on_hold || false,
        terms_of_service: currentProduct.terms_of_service || '',
        warranty: currentProduct.warranty || 0,
        warranty_text: currentProduct.warranty_text || '',
        remove_image: currentProduct.remove_image || false,
        remove_file: currentProduct.remove_file || false,
        volume_discounts: currentProduct.volume_discounts || [{}],
        recurring_interval: currentProduct.recurring_interval || '',
        recurring_interval_count: currentProduct.recurring_interval_count || 0,
        trial_period: currentProduct.trial_period || 0,
      });
    }
  }, [currentProduct]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userData = JSON.parse(localStorage.getItem('user'));
    const isSellix = platform === 'Sellix';
    const url = isSellix ? 'https://dev.sellix.io/v1/products' : 'https://api.billgang.io/v1/products'; // Update with actual Billgang URL
    const apiKey = isSellix ? userData.sellixApiKey : userData.billgangApiKey; // Ensure billgangApiKey exists in userData

    const options = {
      method: currentProduct ? 'PUT' : 'POST',
      headers: {
        Authorization: apiKey,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(product)
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();

      if (data.status === 200) {
        onSave();
      } else {
        console.error('Error creating or updating product:', data.message);
      }
    } catch (err) {
      console.error(err);
    }


    setProduct({
      title: '',
      price: '',
      description: '',
      currency: '',
      stock: '',
      service_text: '',
      gateways: [],
      type: 'SERIALS',
      serials: [],
      serials_remove_duplicates: false,
      dynamic_webhook: '',
      stock_delimiter: '',
      min_quantity: 0,
      max_quantity: 0,
      delivery_text: '',
      custom_fields: [{}],
      crypto_confirmations_needed: 0,
      max_risk_level: 0,
      unlisted: false,
      private: false,
      block_vpn_proxies: false,
      sort_priority: 0,
      webhooks: [],
      on_hold: false,
      terms_of_service: '',
      warranty: 0,
      warranty_text: '',
      remove_image: false,
      remove_file: false,
      volume_discounts: [{}],
      recurring_interval: '',
      recurring_interval_count: 0,
      trial_period: 0,
    });
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6 mb-6">
      <h2 className="text-2xl font-semibold mb-4">{currentProduct ? 'Edit Product' : 'Add Product'}</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700">
            Title:
            <input
              type="text"
              name="title"
              value={product.title}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              required
            />
          </label>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">
            Price:
            <input
              type="number"
              step="0.01"
              name="price"
              value={product.price}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              required
            />
          </label>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">
            Description:
            <textarea
              name="description"
              value={product.description}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
          </label>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">
            Currency:
            <input
              type="text"
              name="currency"
              value={product.currency}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
          </label>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">
            Service Text:
            <input
              type="text"
              name="service_text"
              value={product.service_text}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
          </label>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">
            Stock:
            <input
              type="number"
              name="stock"
              value={product.stock}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            />
          </label>
        </div>
        {/* Add other fields as needed */}
        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
          {currentProduct ? 'Update' : 'Add'}
        </button>
      </form>
    </div>
  );
};

export default ProductForm;
