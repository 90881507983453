import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { auth_service } from '../../utils/auth_service';

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const [error, setError] = useState('');

    const handleResetPassword = async (e) => {
        e.preventDefault();
        try {
            await auth_service.resetPassword(email);
            setEmailSent(true);
        } catch (error) {
            setError(error.message || 'An error occurred while sending reset password email.');
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen">
            <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-md">
                <form onSubmit={handleResetPassword} className="space-y-6">
                    <h1 className="text-2xl font-bold text-center mb-4">Forgot Password</h1>
                    {emailSent ? (
                        <p className="text-green-500 text-center mb-4">
                            An email with instructions to reset your password has been sent to your email address.
                        </p>
                    ) : (
                        <>
                            <p className="text-gray-500 text-center">Please enter your email address to reset your password.</p>
                            <div className="flex flex-col mb-4">
                                <label htmlFor="email" className="text-gray-700 font-bold mb-1">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="w-full px-3 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
                                    required
                                />
                            </div>
                            <button
                                type="submit"
                                className="w-full px-3 py-2 rounded-lg bg-blue-500 text-white font-bold hover:bg-blue-700 focus:outline-none focus:ring-1 focus:ring-blue-500"
                            >
                                Reset Password
                            </button>
                            {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
                        </>
                    )}
                    <p className="text-center mt-4">
                        Remember your password? <Link to="/login" className="text-blue-500 hover:underline">Log in</Link>
                    </p>
                </form>
            </div>
        </div>
    );
}

export default ForgotPassword;
