import axios from './axios';

function register({ email, password, roles }) {
  return new Promise(async (resolve, reject) => {
    try {
      var config = {
        method: 'post',
        url: '/auth/signup',
        data: { email, password, roles },
      };
      const resp = await axios(config);
      const response = resp.data;

      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

function login({ email, password }) {
  return new Promise(async (resolve, reject) => {
    try {
      var config = {
        method: 'post',
        url: '/auth/signin',
        data: { email, password },
      };
      const resp = await axios(config);
      const response = resp.data;

      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

function googlesso({ email }) {
  return new Promise(async (resolve, reject) => {
    try {
      var config = {
        method: 'post',
        url: '/auth/googlesso',
        data: { email },
      };
      const resp = await axios(config);
      const response = resp.data;

      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

function getUser({email}) {
  return new Promise(async (resolve, reject) => {
    try {
      var config = {
        method: 'get',
        url: '/user',
        params: { email }
      };

      const resp = await axios(config);

      const response = resp.data;
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

function fetchRoles() {
  return new Promise(async (resolve, reject) => {
    try {
      var config = {
        method: 'get',
        url: '/roles/signup'
      };

      const resp = await axios(config);

      const response = resp.data;
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

function updateUser(id, userData) {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        method: 'put',
        url: `/user/${id}`,
        data: userData,
      };
      const resp = await axios(config);
      const response = resp.data;

      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}

function resetPassword(email) {
  return new Promise(async (resolve, reject) => {
    try {
      const config = {
        method: 'post',
        url: '/auth/reset-password',
        data: { email },
      };
      const resp = await axios(config);
      const response = resp.data;
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
}


export const auth_service = {
  register,
  login,
  googlesso,
  getUser,
  fetchRoles,
  updateUser,
  resetPassword
};
