import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaCog } from 'react-icons/fa';
import logo from '../../logo.svg';

const Header = () => {
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const handleHome = () => {
        navigate('/');
    };
    
    const handleWalletConnector = () => {
        navigate('/wallet-connector');
    };

    const handleMyWallets = () => {
        navigate('/customers');
    };

    const handleProducts = () => {
        navigate('/products');
    };
    
    const handleLogout = () => {
        localStorage.clear();
        navigate('/login');
    };

    const handleProfileSettings = () => {
        navigate('/profile');
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    return (
        <header className="bg-blue-600 p-4">
            <div className="container mx-auto flex justify-between items-center">
                <div className="flex items-center">
                    <div className="flex items-center cursor-pointer" onClick={handleHome}>
                        <img src={logo} alt="Logo" className="h-8 w-8 mr-2" />
                        <span className="text-white text-xl font-semibold">Sellspin</span>
                    </div>
                    <button
                        onClick={handleMyWallets}
                        className="ml-8 bg-white text-blue-600 px-4 py-2 rounded hover:bg-gray-200"
                    >
                        Customers
                    </button>
                    <button
                        onClick={handleProducts}
                        className="ml-8 bg-white text-blue-600 px-4 py-2 rounded hover:bg-gray-200"
                    >
                        Products
                    </button>
                </div>
                <div className="relative">
                    <button
                        onClick={toggleDropdown}
                        className="bg-white text-blue-600 px-4 py-2 rounded hover:bg-gray-200"
                    >
                        <FaCog className="text-xl" />
                    </button>
                    {dropdownOpen && (
                        <div className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-lg">
                            <button
                                onClick={handleWalletConnector}
                                className="block px-4 py-2 text-left w-full text-blue-600 hover:bg-gray-200"
                            >
                                Wallet Connector
                            </button>
                            <button
                                onClick={handleProfileSettings}
                                className="block px-4 py-2 text-left w-full text-blue-600 hover:bg-gray-200"
                            >
                                Profile Settings
                            </button>
                            <button
                                onClick={handleLogout}
                                className="block px-4 py-2 text-left w-full text-blue-600 hover:bg-gray-200"
                            >
                                Logout
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </header>
    );
};

export default Header;
